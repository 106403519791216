import { betsEpic, betsReducer, cardsEpic, cardsReducer, gameEpic, payoutsEpic, socketEpic, } from './store/index';
import { isOTTABonURL, } from './util/utils';
import handleOperatorError from './store/epics/socket/handleOperatorError';

const noop = () => Promise.resolve({ default: () => null, });

export default () => ({
  components: {
    GameBoard: () => import('./views/GameBoard/GameBoard'),
    WelcomeDialog: () => import('./views/GameDialog/dialogs/Welcome'),
    LeftSideDrawerContent: () => import('./components/LeftSideDrawerContent'),
    ...(isOTTABonURL ? { GameInfo: () => import('./components/GameInfo/OTTAB'), } : {}),
    ResultDisplay: () => import('./components/ResultDisplay'),
    Payouts: () => import('./components/Payouts'),
    BetToolbar: () => import('./components/BetToolbar'),
    VideoOverlay: noop,
    ProgressBar: noop,
    CallBetsToolbar: noop,
  },
  store: {
    epics: [ socketEpic, betsEpic, cardsEpic, gameEpic, ...(isOTTABonURL ? [ payoutsEpic, ] : []), ],
    reducers: {
      bets: betsReducer,
      cards: cardsReducer,
    },
  },
  props: {
    GameLeftSideDrawer: { style: { top: 'calc(100% * -0.18)', }, },
  },
  fetchPayouts: !isOTTABonURL, // let bootstrap fetch payouts for AB classic
  gameBoardHeight: 22,
  desktopBoardToggle: true,
  hideLeftSideDrawer: false,
  landscapeToolbarOrientation: 'vertical',
  handleOperatorError,
});
