/* eslint-disable indent */
import { actions, selectors, } from '@ezugi/bootstrap';
import { LAST_BETS, PLACE_YOUR_BETS, SHUFFLE, } from '@ezugi/constants';
import {
  all,
  clamp,
  compose,
  contains,
  evolve,
  flatten,
  isNil,
  last,
  map,
  mapObjIndexed,
  merge,
  pick,
  prop,
  reduce,
  reject,
  values,
} from 'ramda';
import { STATUS, } from '../constants';
import { BET_TYPES, } from '../../../../constants';
import { SIDEBETS_LIST, } from '../../../../constants/betTypes';
import { INITIAL_STATE, } from '../../../reducers/bets';

import {
  allCurrentBetsSelector,
  currentRoundBetsSelector,
  firstMainBetsSelector,
  firstMainBetsWithSidebetsSelector,
  roundLastBetsSelector,
  secondMainBetsSelector,
  totalFirstBetsSelector,
  totalFirstMainBetsSelector,
  totalSecondBetsSelector,
} from '../../../selectors/bets';
import { getTotalBet, oppositeBetTypesMap, } from '.';
import { isOTTABSelector, } from '../../../selectors/game';
import { isSecondPYBSelector, } from '../../../selectors/round';

const { betActions, } = actions;

// eslint-disable-next-line
const { userBalanceSelector, totalBetSelector, generalConfigSelector, currentLimitsSelector, betsSelector, betsConfigSelector } = selectors;
const {
  dialogActions: { dialog, },
  notificationActions: { notification, },
} = actions;
const statusPriorityMap = {
  [STATUS.BETTING_NOT_ALLOWED]: 6,
  [STATUS.NOT_ALLOWED]: 5,
  [STATUS.INVALID_BALANCE]: 4,
  [STATUS.OVER_DOUBLE_FIRST_BET]: 3,
  [STATUS.OVER_INDEX_LIMIT]: 2,
  [STATUS.OVER_TABLE_LIMIT]: 2,
  [STATUS.BELOW_INDEX_LIMIT]: 1,
  [STATUS.BELOW_TABLE_LIMIT]: 1,
  [STATUS.VALID]: 0,
};

const LOW_BALANCE_DIALOG = 'LowBalanceDialog';
export const RESULT_SEED = { ok: true, valid: true, status: STATUS.VALID, };

const getSimpleNotificationPayload = ({ status, value, betAutoAdjust, maxTableLimit, maxBet, minBet, }) => {
  const payload = (() => {
    switch (status) {
      case STATUS.NOT_ALLOWED:
        return { message: 'notifications.bet_not_allowed', };
      case STATUS.INVALID_BALANCE:
        return { message: 'messages.low_balance_message', };
      case STATUS.OVER_DOUBLE_FIRST_BET:
          return betAutoAdjust
            ? {
              autoAdjust: true,
              event_name: STATUS.OVER_DOUBLE_FIRST_BET, // eslint-disable-line camelcase
              message: 'notifications.bet_over_double_first_bet_adjusted',
            }
            : { message: 'notifications.bet_over_double_first_bet', variables: { doubleFirstBet: maxBet, }, };
      case STATUS.OVER_TABLE_LIMIT:
        return value
          ? { message: 'notifications.bet_over_table_max_limit_adjusted', }
          : { message: 'notifications.bet_over_table_max_limit', variables: { maxTableLimit, }, };
      case STATUS.OVER_INDEX_LIMIT:
        return { message: 'notifications.bet_over_index_max_limit_adjusted', variables: { maxIndexLimit: maxBet, }, };
      case STATUS.BELOW_INDEX_LIMIT:
        return betAutoAdjust
          ? {
            autoAdjust: true,
            // eslint-disable-next-line
            event_name: STATUS.BELOW_INDEX_LIMIT,
            message: 'notifications.bet_below_index_min_limit_adjusted',
          }
          : { message: 'notifications.bet_below_index_min_limit', variables: { minIndexLimit: minBet, }, };
      default:
        return null;
    }
  })();
  return payload;
};

const getGroupNotificationPayload = ({ status, }) => {
  const payload = (() => {
    switch (status) {
      case STATUS.NOT_ALLOWED:
        return { message: 'notifications.bet_not_allowed', };
      case STATUS.INVALID_BALANCE:
        return { message: 'messages.low_balance_message', };
      case STATUS.OVER_INDEX_LIMIT:
      case STATUS.OVER_DOUBLE_FIRST_BET:
      // case STATUS.OVER_TABLE_LIMIT:
        return { message: 'notifications.group_bets_over_max_limits', };
      case STATUS.BELOW_INDEX_LIMIT:
      case STATUS.BELOW_TABLE_LIMIT:
        return { message: 'notifications.group_bet_below_min_limits', };
      default:
        return null;
    }
  })();

  return payload;
};

const pipeValidations = (validations) => (bet, state) => reduce(
  (res, fn) => (!res.valid ? res : fn(res.bet, state, res.actions)),
  {
    ...RESULT_SEED,
    bet,
  },
  validations
);

export const getBetTypeLimits = (() => {
  const cache = {};
  let currentLimits;

  return (type, state) => {
    if (cache[type]) {
      return cache[type];
    }

    currentLimits = currentLimits || currentLimitsSelector(state);

    const limits = { min: 0, max: 0, };

    switch (type) {
      case BET_TYPES.ANDAR:
      case BET_TYPES.BAHAR:
      case BET_TYPES.ANDAR_FIRST:
      case BET_TYPES.ANDAR_SECOND:
      case BET_TYPES.BAHAR_FIRST:
      case BET_TYPES.BAHAR_SECOND: {
        limits.min = currentLimits.Min_Bet;
        limits.max = currentLimits.Max_Bet;
        break;
      }
      case BET_TYPES.ONE_TO_FIVE:
      case BET_TYPES.SIX_TO_TEN:
      case BET_TYPES.ELEVEN_TO_FIFTEEN:
      case BET_TYPES.SIXTEEN_TO_TWENTYFIVE:
      case BET_TYPES.TWENTYSIX_TO_THIRTY:
      case BET_TYPES.THIRTYONE_TO_THIRTYFIVE:
      case BET_TYPES.THIRTYSIX_TO_FOURTY:
      case BET_TYPES.FOURTYONE_OR_MORE: {
        limits.min = currentLimits[`Min_Group${SIDEBETS_LIST.indexOf(type) + 1}`];
        limits.max = currentLimits[`Max_Group${SIDEBETS_LIST.indexOf(type) + 1}`];
        break;
      }
      default:
        break;
    }

    return limits;
  };
})();

export const doubleBet = (state) => mapObjIndexed((b, key) => {
  const { min, } = getBetTypeLimits(key, state);
  const value = b.value * 2;
  const valid = value >= min;
  return { ...b, value, valid, };
});

export const doubleBets = (state) => compose(
  evolve({
    current: doubleBet(state),
    totalBet: (t) => t * 2,
  }),
  betsSelector
)(state);

export const doubleSecondBets = (state) => { // OTTAndarBahar
  const firstBets = firstMainBetsWithSidebetsSelector(state);
  const secondBets = secondMainBetsSelector(state);
  // bets doubled
  const doubledSecondBets = doubleBet(state)(secondBets);
  const doubledCurrentBets = merge(firstBets, doubledSecondBets);
  const doubledTotalBet = getTotalBet(doubledCurrentBets);

  return {
    current: doubledCurrentBets,
    totalBet: doubledTotalBet,
  };
};

export const validateRoundStatus = (bet, { round: { roundStatus, }, }) => {
  const ok = contains(roundStatus, [ SHUFFLE, PLACE_YOUR_BETS, LAST_BETS, ]);
  return {
    ok,
    valid: ok,
    status: ok ? STATUS.VALID : STATUS.BETTING_NOT_ALLOWED,
    bet,
    actions: [],
  };
};


export const validateCoverage = (bet, state) => {
  const isOTTAB = isOTTABSelector(state);
  const currentBets = allCurrentBetsSelector(state);
  const { allowOpposite, } = betsConfigSelector(state) ?? {};

  const isOpposite = oppositeBetTypesMap[bet.type]?.some?.((betType) => betType in currentBets);
  // never allow opposite bets on ottab
  const ok = isOTTAB ? !isOpposite : (allowOpposite || !isOpposite);

  return {
    ok,
    valid: ok,
    status: ok ? STATUS.VALID : STATUS.BETTING_NOT_ALLOWED,
    bet,
    actions: !ok ? [ notification.add({ message: 'notifications.bet_not_allowed', }), ] : [],
  };
};


export const validateBalance = (bet, state) => {
  const balance = userBalanceSelector(state);
  const totalBet = totalBetSelector(state);

  const ok = balance - totalBet >= bet.value;
  const status = ok ? STATUS.VALID : STATUS.INVALID_BALANCE;

  return {
    ok,
    valid: ok,
    status,
    bet,
    actions: [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ],
  };
};

/** Returns `true` when OTT AB second bet is not greater than the double of the OTT AB first bet */
const isValidSecondBetVsFirstBet = (secondBetAmount, firstBetAmount) => secondBetAmount <= 2 * firstBetAmount;

const validateOTTABSecondBet = (bet, state) => {
  const isOTTAB = isOTTABSelector(state);
  const isSecondPYB = isSecondPYBSelector(state);
  if (isOTTAB && isSecondPYB) {
    const firstMainBets = firstMainBetsSelector(state);
    const totalFirstMainBet = reduce((acc, { value, }) => acc + value, 0, values(firstMainBets));

    const currentBets = allCurrentBetsSelector(state);
    const existingBet = (currentBets[bet.type] || {}).value || 0;

    const ok = false;

    if (!isValidSecondBetVsFirstBet(bet.value + existingBet, totalFirstMainBet)) {
      const { betAutoAdjust, } = generalConfigSelector(state);
      const status = STATUS.OVER_DOUBLE_FIRST_BET;
      const value = betAutoAdjust ? 2 * totalFirstMainBet - existingBet : bet.value;

      const _actions = [ notification.add(
        getSimpleNotificationPayload({
          status,
          betAutoAdjust,
          maxBet: 2 * totalFirstMainBet,
        })
      ), ];

      return {
        ok,
        valid: betAutoAdjust,
        status,
        bet: {
            ...bet,
            value,
            valid: betAutoAdjust,
        },
        actions: _actions,
      };
    }
  }

  return {
    ok: true,
    valid: true,
    status: STATUS.VALID,
    bet,
    actions: [ ],
  };
};

export const validateLimits = (bet, state, prevValidationActions) => {
  const balance = userBalanceSelector(state);
  const currentBets = allCurrentBetsSelector(state);
  const { min: minBet, max: maxBet, } = getBetTypeLimits(bet.type, state);
  const existingBet = (currentBets[bet.type] || {}).value || 0;

  const { betAutoAdjust, } = generalConfigSelector(state);
  let status = bet.value + existingBet < minBet
    ? STATUS.BELOW_INDEX_LIMIT
    : bet.value + existingBet > maxBet
      ? STATUS.OVER_INDEX_LIMIT
      : STATUS.VALID;

  let ok = status === STATUS.VALID || (bet.hasOwnProperty('valid') && !bet.valid);
  const diff = maxBet - existingBet;

  let value = ok
    ? bet.value
    : clamp(betAutoAdjust ? minBet - existingBet : bet.value > diff ? diff : bet.value, diff, bet.value);

  // const { Max_Bet: maxTableLimit, } = currentLimitsSelector(state);

  const totalBet = totalBetSelector(state);

  // if (totalBet + value > maxTableLimit) {
  //   status = STATUS.OVER_TABLE_LIMIT;
  //   ok = false;
  //   value = maxTableLimit - totalBet;
  // }

  if (value > balance - totalBet) {
    status = STATUS.INVALID_BALANCE;
    ok = false;
    value = 0;
  }

  const valid = !contains(status, [ STATUS.INVALID_BALANCE, STATUS.BETTING_NOT_ALLOWED, ]);

  const _actions = [
    ...(status === STATUS.INVALID_BALANCE
      ? [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ]
      : reject(
        (i) => isNil(i) || isNil(i.payload),
        [
          ...(prevValidationActions || []),
          notification.add(
            getSimpleNotificationPayload({
              status,
              value,
              betAutoAdjust,
              minBet,
              maxBet,
              // maxTableLimit,
            })
          ),
        ]
      )),
  ];

  return {
    ok,
    valid,
    status,
    bet: {
      ...(value && {
        ...bet,
        value,
        valid: !(status === STATUS.BELOW_INDEX_LIMIT && !betAutoAdjust && value + existingBet < minBet),
      }),
    },
    actions: _actions,
  };
};

export function validateBetUndo(_, state) {
  const h = [ ...state.bets.history, ];
  h.pop();
  const s = last(h) || INITIAL_STATE;
  const b = userBalanceSelector(state);

  const ok = s.totalBet <= b;

  return ok
    ? { ok, actions: [ betActions.history.apply({ ...s, history: h, }), ], }
    : { ok, actions: [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ], };
}

export const validateBet = pipeValidations([
  validateRoundStatus,
  validateCoverage,
  validateBalance,
  validateOTTABSecondBet,
  validateLimits,
]);
const isOk = prop('ok');

export function validateBets(_bets, state) {
  const bets = mapObjIndexed((value, key) => ({
    type: key,
    ...value,
  }))(_bets);

  const results = map((bet) => validateBet(bet, state))(bets);
  const ok = all(isOk, values(results));
  const status = compose(
    reduce((prev, next) => {
      const prevPriority = statusPriorityMap[prev];
      const nextPriority = statusPriorityMap[next];

      return nextPriority > prevPriority ? next : prev;
    }, STATUS.VALID),
    map(prop('status'))
  )(values(results));

  return {
    ok,
    status,
    actions: compose(
      flatten,
      pick([ 'actions', ]),
      values,
    )(results),
  };
}

export function validateRebet(_, state) {
  const lb = roundLastBetsSelector(state);
  const b = userBalanceSelector(state);

  const ok = lb.totalBet <= b;

  return ok
    ? {
      ok,
      actions: [ betActions.bet.apply(lb), ],
    }
    : {
      ok,
      actions: [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ],
    };
}

const double = (b) => +b * 2 || b;
const validatePYB = ({ totalBet, balance, }) => (double(totalBet) > balance ? STATUS.INVALID_BALANCE : STATUS.VALID);
const validateSecondPYB = ({ totalFirstMainBets, totalFirstBets, totalSecondBets, balance, }) => {
  if (!isValidSecondBetVsFirstBet(double(totalSecondBets), totalFirstMainBets)) return STATUS.OVER_DOUBLE_FIRST_BET;
  if (double(totalSecondBets) + totalFirstBets > balance) return STATUS.INVALID_BALANCE;
  return STATUS.VALID;
};

export function validateDouble(_, state) {
  const isOTTAB = isOTTABSelector(state);
  const isSecondPYB = isSecondPYBSelector(state);
  const userBets = {
    current: currentRoundBetsSelector(state),
    totalBet: totalBetSelector(state),
    balance: userBalanceSelector(state),
    // OTTAndarBahar
    ...(isOTTAB ? { totalFirstBets: totalFirstBetsSelector(state), } : {}),
    ...(isOTTAB ? { totalFirstMainBets: totalFirstMainBetsSelector(state), } : {}),
    ...((isOTTAB && isSecondPYB) ? { totalSecondBets: totalSecondBetsSelector(state), } : {}),
  };

  const status = (isOTTAB && isSecondPYB) ? validateSecondPYB(userBets) : validatePYB(userBets);
  const ok = status === STATUS.VALID;
  const _actions = ok ? []
   : status === STATUS.INVALID_BALANCE ? [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ]
   : [ notification.add(getGroupNotificationPayload({ status, })), ];

  let result = {
    status,
    ok,
    valid: ok,
    actions: _actions,
  };

  if (status === STATUS.VALID) {
    result = { ...result, ...validateBets(userBets.current, state), };
    result.actions = result.ok
      ? [ betActions.bet.apply((isOTTAB && isSecondPYB) ? doubleSecondBets(state) : doubleBets(state)), ]
      : [ notification.add(getGroupNotificationPayload({ status: result.status, })), ];
    result.valid = result.ok;

    if (result.status === STATUS.BELOW_INDEX_LIMIT) {
      result.actions = [
        betActions.bet.apply((isOTTAB && isSecondPYB) ? doubleSecondBets(state) : doubleBets(state)),
        notification.add(getGroupNotificationPayload({ status: result.status, })),
      ];
    }
  }

  return result;
}
