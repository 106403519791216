import { defaultTo, isEmpty, pipe, reduce, } from 'ramda';
import { concat, of, } from 'rxjs';

import { actions as bootstrapActions, } from '@ezugi/bootstrap';
import { RESULT_SEED, } from '../bets/utils/validations';
import { getTotalBet, } from '../bets/utils';

const {
  notificationActions: { notification, },
  betActions,
} = bootstrapActions;


const handleOperatorError = (socketMessage) => {
  const { BetsList, } = socketMessage;

  // filter non approved bets and update bets store to reflect approved bets from GE
  const validBetsList = pipe(
    defaultTo([]),
    reduce(
      (acc, bet) => ({
        ...acc,
        ...(bet.status === 'APPROVED' ? { [bet.type]: { value: bet.betAmount, ...RESULT_SEED, }, } : {}),
      }),
      {}
    ),
  )(BetsList);

  return concat(of(
    ...(isEmpty(validBetsList)
      ? [ betActions.bet.reset(), ]
      : [
        betActions.bet.apply({
          current: validBetsList,
          totalBet: getTotalBet(validBetsList),
        }),
      ]),
    notification.add({ message: 'messages.bet_too_late', })
  ));
};

export default handleOperatorError;
