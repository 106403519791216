import {
  compose, reduce, add, values, map, prop,
} from 'ramda';

import { MAIN_BETS, } from '../../../../constants/betTypes';

export const getTotalBet = compose(
  reduce(add, 0),
  map(prop('value')),
  values
);

export const oppositeBetTypesMap = {
  [MAIN_BETS.ANDAR]: [ MAIN_BETS.BAHAR, MAIN_BETS.BAHAR_FIRST, MAIN_BETS.BAHAR_SECOND, ],
  [MAIN_BETS.BAHAR]: [ MAIN_BETS.ANDAR, MAIN_BETS.ANDAR_FIRST, MAIN_BETS.ANDAR_SECOND, ],
  [MAIN_BETS.ANDAR_FIRST]: [ MAIN_BETS.BAHAR, MAIN_BETS.BAHAR_FIRST, MAIN_BETS.BAHAR_SECOND, ],
  [MAIN_BETS.BAHAR_FIRST]: [ MAIN_BETS.ANDAR, MAIN_BETS.ANDAR_FIRST, MAIN_BETS.ANDAR_SECOND, ],
  [MAIN_BETS.ANDAR_SECOND]: [ MAIN_BETS.BAHAR, MAIN_BETS.BAHAR_FIRST, MAIN_BETS.BAHAR_SECOND, ],
  [MAIN_BETS.BAHAR_SECOND]: [ MAIN_BETS.ANDAR, MAIN_BETS.ANDAR_FIRST, MAIN_BETS.ANDAR_SECOND, ],
};
